import "./instrumentation";

import React, { type ComponentType, Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Router, Route } from "wouter";
import RootLayout from "./app/layout";
import { ErrorBoundary } from "@sentry/react";

const pages = import.meta.glob("./app/**/page.tsx") as Record<string, () => Promise<{ default: ComponentType }>>;

const AppMapping = Object.entries(pages)
	.filter(([path]) => !path.includes("["))
	.map(([path, component]) => ({
		// remove "./app/" and "/page.tsx"
		path: "/" + path.split("/").slice(2, -1).join("/"),
		Component: lazy(component),
	}));

const root = document.getElementById("root")!;

const Root = () => {
	return (
		<React.StrictMode>
			<ErrorBoundary fallback={<div>An error occurred. Our team has been notified.</div>} showDialog>
				<RootLayout>
					<Router>
						{AppMapping.map(({ path, Component }) => (
							<Route key={path} path={path}>
								<Suspense fallback={<div></div>}>
									<Component />
								</Suspense>
							</Route>
						))}
					</Router>
				</RootLayout>
			</ErrorBoundary>
		</React.StrictMode>
	);
};

createRoot(root).render(<Root />);
