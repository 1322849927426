// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/react/

import * as Sentry from "@sentry/react";

const SENTRY_DSN =
	import.meta.env.VITE_SENTRY_DSN ||
	"https://59df8e1bf28cf16855d5e316cacb37e6@o4507919477833728.ingest.us.sentry.io/4507919480913920";

const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT || "development";

Sentry.init({
	dsn: SENTRY_DSN,
	enabled: environment !== "development", // Disable in development just to keep the console clean, enabled in production

	// Add optional integrations for additional features
	integrations: [Sentry.replayIntegration()],

	// Define how likely traces are sampled
	tracesSampleRate: 1,

	// Define how likely Replay events are sampled
	replaysSessionSampleRate:
		environment === "development"
			? 0
			: (() => {
					switch (environment) {
						case "production":
							return 0.1;
						case "staging":
							return 0.5;
						default:
							return 0;
					}
				})(),

	// Define how likely Replay events are sampled when an error occurs
	replaysOnErrorSampleRate: environment === "development" ? 0 : 1.0,

	// Setting this option to true will print useful information to the console while you're setting up Sentry
	debug: false, // keep this disabled if you want to avoid clogging up the console or need to find an error and this is not the cause
});
